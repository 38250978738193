import moment from 'moment'
import React, { useState } from 'react'
import { Button, Input } from 'reactstrap'
import Reflux from 'reflux'

const CommentSection = props => {
  const [newCommentMessage, setNewCommentMessage] = useState('')
  const userId = Reflux.GlobalState.LoginStore.user.UserID

  const onCommentCreate = () => {
    props.createComment(newCommentMessage)
    setNewCommentMessage('')
  }

  const onCommentRemove = comment => {
    if (confirm('Er du sikker på du vil slette denne kommentar?')) {
      props.removeComment(comment)
    }
  }

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      onCommentCreate()
    }
  }

  return (
    <>
      <div style={{ display: 'flex', gap: 20, alignItems: 'end' }}>
        <Input
          type='textarea'
          rows={1}
          value={newCommentMessage}
          onChange={v => setNewCommentMessage(v.target.value)}
          style={{ maxWidth: 700 }}
          onKeyDown={handleKeyPress}
        />
        <Button onClick={onCommentCreate} style={{ height: 36 }}>
          Kommentér
        </Button>
      </div>

      <div style={{ marginTop: 20 }}>
        {props.comments.toReversed().map(comment => (
          <div
            key={comment.timeInsert}
            style={{
              borderLeft: '2px solid',
              borderLeftColor: 'grey',
              width: '100%',
              position: 'relative',
              paddingTop: 40,
              paddingLeft: 15
            }}
          >
            <div style={{ width: 8, height: 8, borderRadius: 50, backgroundColor: '#28D094', position: 'absolute', left: -5, top: 48 }}></div>
            <div style={{ fontSize: 16, fontWeight: '600' }}>{comment.commentedBy}</div>
            <div style={{ fontSize: 14 }}>{comment.message}</div>
            <div style={{ fontSize: 12, opacity: 0.7, marginTop: 2, display: 'flex', gap: 10 }}>
              {moment(comment.timeInsert).format('D/M/YYYY, HH:mm')}
              {userId === comment.UserID && (
                <span style={{ cursor: 'pointer' }} onClick={() => onCommentRemove(comment)}>
                  Slet
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default CommentSection
